import React from 'react';
import { Link } from 'gatsby';

interface BlogPaginationProps {
  currentPage: number;
  numPages: number;
  baseUrl?: string;
}
export const BlogPagination: React.FC<BlogPaginationProps> = ({
  currentPage,
  numPages,
  baseUrl = `/blog`,
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `/` : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <ul
      style={{
        display: `flex`,
        flexWrap: `wrap`,
        justifyContent: `space-between`,
        alignItems: `center`,
        listStyle: `none`,
        padding: 0,
        width: `fit-content`,
        margin: `35px auto`,
      }}
    >
      {!isFirst && (
        <Link to={`${baseUrl}${prevPage}`} rel="prev">
          ← Page Précédente
        </Link>
      )}
      {Array.from({ length: numPages }, (_, i) => (
        <li
          key={`pagination-number${i + 1}`}
          style={{
            margin: 0,
          }}
        >
          <Link
            to={`${baseUrl}/${i === 0 ? `` : i + 1}`}
            style={{
              padding: `10px 15px`,
              textDecoration: `none`,
              borderRadius: `3px`,
              color: i + 1 === currentPage ? `#ffffff` : ``,
              background: i + 1 === currentPage ? `#b35a18b8` : ``,
            }}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!isLast && (
        <Link to={`${baseUrl}/${nextPage}`} rel="next">
          Page Suivante →
        </Link>
      )}
    </ul>
  );
};
