import React from 'react';
import Articles from 'components/articles';
import Layout from 'components/layout';
import Seo from 'components/seo';
import {
  ArticleData,
  ArticleCategory,
  HomePageData,
  JourneyStep,
  GatsbyResultSet,
} from 'models';
import { Typography } from 'antd';
import { graphql, PageProps } from 'gatsby';
import { BlogPagination } from '@/components/blog-pagination';

interface AllArticlesProps {
  articles: GatsbyResultSet<ArticleData>;
  categories: GatsbyResultSet<ArticleCategory>;
  homepage: HomePageData;
  journeySteps: JourneyStep[];
}

const AllArticles: React.FC<PageProps<AllArticlesProps>> = ({
  data,
  pageContext,
}) => {
  const [articles] = React.useState<ArticleData[]>(
    data.articles.edges.map((x) => x.node),
  );
  const [categories] = React.useState<ArticleCategory[]>(
    data.categories.edges.map((x) => x.node),
  );

  const { currentPage, numPages }: { currentPage: number; numPages: number } =
    pageContext as any;
  return (
    <Layout expand={false}>
      <Seo
        seo={{
          metaDescription: `Actus, conseils et retours d'expérience`,
          metaTitle: `Blog`,
          ogType: `website`,
        }}
      />
      <Typography.Title level={1}>Actus, conseils et retex</Typography.Title>
      <Articles articles={articles} categories={categories} />
      <BlogPagination currentPage={currentPage} numPages={numPages} />
    </Layout>
  );
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    categories: allStrapiCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    articles: allStrapiArticle(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          published_at(locale: "fr", formatString: "DD MMMM YYYY")

          strapiId
          slug
          title
          category {
            name
          }
          description
          image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(
                  height: 450
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
  }
`;
export default AllArticles;
